import React, { useEffect, useState } from 'react';
import { auth, db, storage } from './firebase'; // Firebase setup
import { collection, query, where, getDocs, doc, deleteDoc, getDoc, updateDoc, orderBy } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage'; // Firebase storage functions
import { useNavigate } from 'react-router-dom'; // For navigating to create/edit/view profile
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { FaEye, FaUsers } from 'react-icons/fa'; // Import icons
import { formatDistanceToNow, format } from 'date-fns';
import './UserProfiles.css';
import { ClockIcon } from '@heroicons/react/24/solid'; // Solid style
import { StarIcon } from '@heroicons/react/24/solid';
import { TrophyIcon } from '@heroicons/react/24/solid'; // Example replacement for the price ribbon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faClock, faStar, faTrophy, faUsers } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal'; // Assuming you have a reusable Modal component



function UserProfiles() {
    const [profiles, setProfiles] = useState([]);
    const [recentScans, setRecentScans] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [filteredScans, setFilteredScans] = useState([]);
    const [filter, setFilter] = useState('all');
    const [scanStats, setScanStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [user, setUser] = useState(null);
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'activity');
    const [allAchievements, setAllAchievements] = useState([]);
    const [userAchievements, setUserAchievements] = useState([]);
    const [ownedTeams, setOwnedTeams] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [teamToRemove, setTeamToRemove] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {

        const checkAuthAndFetchData = async () => {
            onAuthStateChanged(auth, async (currentUser) => {
                if (currentUser) {
                    setUser(currentUser);
                    fetchProfiles(currentUser);
                    fetchRecentActivity(currentUser);
                    fetchFavorites(currentUser);
                    checkAdminRole(currentUser);
                    await fetchAllAchievements();
                    await fetchUserAchievements(currentUser);
                    await fetchOwnedTeams();
                } else {
                    navigate('/login');
                }
            });
        };

        const fetchOwnedTeams = async () => {
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                try {
                    const q = query(collection(db, 'teams'), where('ownerId', '==', userId)); // Assuming 'ownerId' field denotes ownership
                    const querySnapshot = await getDocs(q);
                    const teams = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setOwnedTeams(teams);
                } catch (error) {
                    console.error('Error fetching owned teams:', error);
                }
            }
        };
        const fetchAllAchievements = async () => {
            try {
                // Create a query that orders the achievements by NumberOfScansRequired in ascending order
                const achievementsQuery = query(
                    collection(db, 'achievements'),
                    orderBy('NumberOfScansRequired', 'asc')
                );

                const achievementsSnapshot = await getDocs(achievementsQuery);
                const achievementsList = achievementsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setAllAchievements(achievementsList);
            } catch (error) {
                console.error('Error fetching achievements:', error);
            }
        };

        const fetchUserAchievements = async (currentUser) => {
            try {
                const docRef = doc(db, 'users', currentUser.uid, 'achievements', 'earned');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserAchievements(docSnap.data().achievements || []);
                }
            } catch (error) {
                console.error('Error fetching user achievements:', error);
            }
        };

        const fetchProfiles = async (currentUser) => {
            try {
                const q = query(
                    collection(db, 'profiles'),
                    where('userId', '==', currentUser.uid)
                );
                const querySnapshot = await getDocs(q);
                const userProfiles = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetch scanning stats for each profile
                const stats = {};
                for (const profile of userProfiles) {
                    const statsDoc = await getDoc(doc(db, 'scanStats', profile.id));
                    if (statsDoc.exists()) {
                        const data = statsDoc.data();
                        stats[profile.id] = {
                            totalScans: data.totalScans || 0,
                            uniqueVisitors: data.uniqueVisitors ? Object.keys(data.uniqueVisitors).length : 0 // Count unique visitor IDs
                        };
                    }
                }

                setProfiles(userProfiles);
                setScanStats(stats);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };
        const fetchFavorites = async (currentUser) => {
            const favorites = await getDoc(doc(db, 'users', currentUser.uid, 'activity', 'favorites'));
            const fetchProfileData = async (profileId) => {
                const profileDoc = await getDoc(doc(db, 'profiles', profileId));
                return profileDoc.exists() ? { id: profileId, ...profileDoc.data() } : null;
            };
            if (favorites.exists()) {
                const favoriteslist = await Promise.all(
                    (favorites.data().favorites || []).map(async (favorite) => {
                        const profile = await fetchProfileData(favorite.id);
                        return profile ? { ...profile, timestamp: favorite.timestamp } : null;
                    })
                );

                // Filter out null entries
                const filteredFavorites = favoriteslist.filter(profile => profile !== null);

                setFavorites(filteredFavorites);
            }
        };
        const fetchRecentActivity = async (currentUser) => {
            try {
                const recentScansDoc = await getDoc(doc(db, 'users', currentUser.uid, 'activity', 'recentScans'));
                const fetchProfileData = async (profileId) => {
                    const profileDoc = await getDoc(doc(db, 'profiles', profileId));
                    return profileDoc.exists() ? { id: profileId, ...profileDoc.data() } : null;
                };

                if (recentScansDoc.exists()) {
                    const scans = await Promise.all(
                        (recentScansDoc.data().scans || []).map(async (scan) => {
                            const profile = await fetchProfileData(scan.id);
                            return profile ? { ...profile, timestamp: scan.timestamp } : null;
                        })
                    );

                    // Filter for only the last activity per car
                    const latestScansMap = scans.reduce((acc, scan) => {
                        if (!scan) return acc;
                        if (!acc[scan.id] || acc[scan.id].timestamp < scan.timestamp) {
                            acc[scan.id] = scan; // Keep the latest scan for each car ID
                        }
                        return acc;
                    }, {});

                    const latestScans = Object.values(latestScansMap);

                    setRecentScans(latestScans); // Update state with only the latest scans
                    setFilteredScans(latestScans); // Initialize filtered scans
                }
            } catch (error) {
                console.error('Error fetching recent activity:', error);
            }
        };

        const checkAdminRole = async (currentUser) => {
            try {
                const docRef = doc(db, 'roles', currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists() && docSnap.data().role === 'admin') {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error('Error checking admin role:', error);
            }
        };

        checkAuthAndFetchData();
    }, [navigate]);

    const sasUrl = process.env.REACT_APP_AZURE_SAS_URL;



    const handleEdit = (profileId) => {
        navigate(`/edit-profile/${profileId}`);
    };

    const handleView = (profileId) => {
        navigate(`/car-details/${profileId}`);
    };

    const handleCreateProfile = () => {
        navigate('/create-profile');
    };

    const handleGenerateQR = () => {
        navigate('/admin-generate-qr');
    };

    const handleClearAll = async () => {
        if (!user) return;
        try {
            await updateDoc(doc(db, 'users', user.uid, 'activity', 'recentScans'), { scans: [] });
            setRecentScans([]);
            setFilteredScans([]);
            console.log('Scans cleared successfully.');
        } catch (error) {
            console.error('Error clearing scans:', error);
        }
    };
    const handleRemoveTeamClick = (teamId) => {
        setTeamToRemove(teamId);
        setShowRemoveModal(true);
    };
    const handleConfirmRemove = async () => {
        try {
            if (teamToRemove) {
                await deleteDoc(doc(db, 'teams', teamToRemove));
                // Update your state to remove the team from the list
                setOwnedTeams(ownedTeams.filter(team => team.id !== teamToRemove));
            }
        } catch (error) {
            console.error('Error removing team:', error);
            alert('There was an issue removing the team. Please try again.');
        } finally {
            setShowRemoveModal(false);
            setTeamToRemove(null);
        }
    };
    const handleRemoveScan = async (scanId) => {
        if (!user) return;
        const updatedScans = recentScans.filter(scan => scan.id !== scanId);
        try {
            await updateDoc(doc(db, 'users', user.uid, 'activity', 'recentScans'), { scans: updatedScans });
            setRecentScans(updatedScans);
            setFilteredScans(updatedScans);
            console.log('Scan removed successfully.');
        } catch (error) {
            console.error('Error removing scan:', error);
        }
    };

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
        const now = new Date();
        let filtered = [];

        const getDateFromTimestamp = (timestamp) => {
            // Check if the timestamp is a Firestore Timestamp object and convert it
            if (timestamp && typeof timestamp.toDate === 'function') {
                return timestamp.toDate();
            }
            return new Date(timestamp);
        };

        switch (selectedFilter) {
            case 'today':
                filtered = recentScans.filter(scan => getDateFromTimestamp(scan.timestamp).toDateString() === now.toDateString());
                break;
            case 'thisWeek':
                filtered = recentScans.filter(scan => {
                    const scanDate = getDateFromTimestamp(scan.timestamp);
                    const weekStart = new Date(now);
                    weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Set to start of the week (Sunday)
                    return scanDate >= weekStart;
                });
                break;
            case 'thisMonth':
                filtered = recentScans.filter(scan => {
                    const scanDate = getDateFromTimestamp(scan.timestamp);
                    return scanDate.getMonth() === now.getMonth() && scanDate.getFullYear() === now.getFullYear();
                });
                break;
            case 'last6Months':
                filtered = recentScans.filter(scan => {
                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(now.getMonth() - 6);
                    const scanDate = getDateFromTimestamp(scan.timestamp);
                    return scanDate >= sixMonthsAgo;
                });
                break;
            case 'thisYear':
                filtered = recentScans.filter(scan => getDateFromTimestamp(scan.timestamp).getFullYear() === now.getFullYear());
                break;
            case 'all':
            default:
                filtered = recentScans;
        }

        setFilteredScans(filtered);
    };


    const formatTimestamp = (timestamp) => {
        // Check if the timestamp is a Firestore Timestamp object
        if (timestamp && typeof timestamp.toDate === 'function') {
            const parsedDate = timestamp.toDate();
            return formatDateOutput(parsedDate);
        }

        // If the timestamp is already a Date object
        if (timestamp instanceof Date) {
            return formatDateOutput(timestamp);
        }

        // If the timestamp is a string or other format, try parsing it
        try {
            const parsedDate = new Date(timestamp);
            if (isNaN(parsedDate.getTime())) {
                console.error('Invalid Date parsed:', timestamp);
                return 'Invalid Date';
            }
            return formatDateOutput(parsedDate);
        } catch (error) {
            console.error('Error parsing timestamp:', error);
            return 'Invalid Date';
        }
    };

    const formatDateOutput = (parsedDate) => {
        const now = new Date();

        if (parsedDate.toDateString() === now.toDateString()) {
            return formatDistanceToNow(parsedDate, { addSuffix: true });
        }

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (parsedDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        }

        return format(parsedDate, 'MMMM d, yyyy, h:mm a');
    };

    const isAchievementEarned = (achievementId) => {
        return userAchievements.includes(achievementId);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-none">
                <Header />
            </div>
            <div className="flex-grow bg-white text-black relative">
                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <div className="loader">Loading...</div>
                        {/* Replace with any custom loader/spinner component or text */}
                    </div>
                ) : (
                    <div className="fixed bottom-0 w-full p-2 flex justify-around text-white" style={{ background: '#00AEEF' }}>
                        <button
                            onClick={() => handleTabChange('activity')}
                            className={`flex flex-col items-center ${activeTab === 'activity' ? 'text-white' : 'text-black'}`}
                        >
                            <FontAwesomeIcon icon={faClock} className="h-6 w-6" />
                            <span className="text-xs mt-1">Activity</span>
                        </button>
                        <button
                            onClick={() => handleTabChange('favorites')}
                            className={`flex flex-col items-center ${activeTab === 'favorites' ? 'text-white' : 'text-black'}`}
                        >
                            <FontAwesomeIcon icon={faStar} className="h-6 w-6" />
                            <span className="text-xs mt-1">Favorites</span>
                        </button>
                        <button
                            onClick={() => handleTabChange('profiles')}
                            className={`flex flex-col items-center ${activeTab === 'profiles' ? 'text-white' : 'text-black'}`}
                        >
                            <FontAwesomeIcon icon={faCar} className="h-6 w-6" />
                            <span className="text-xs mt-1">Profiles</span>
                        </button>
                        {(
                            <button
                                onClick={() => handleTabChange('teams')}
                                className={`flex flex-col items-center ${activeTab === 'teams' ? 'text-white' : 'text-black'}`}
                            >
                                <FontAwesomeIcon icon={faUsers} className="h-6 w-6" />
                                <span className="text-xs mt-1">Teams</span>
                            </button>
                        )}
                        <button
                            onClick={() => handleTabChange('achievements')}
                            className={`flex flex-col items-center ${activeTab === 'achievements' ? 'text-white' : 'text-black'}`}
                        >
                            <FontAwesomeIcon icon={faTrophy} className="h-6 w-6" />
                            <span className="text-xs mt-1">Achievements</span>
                        </button>
                    </div>
                )}
                <div className="min-h-screen bg-white text-black p-4">
                    {activeTab === 'achievements' && (
                        <div>
                            <h2 className="text-2xl font-bold mb-4">Achievements</h2>
                            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full">
                                {allAchievements.map((achievement) => (
                                    <div
                                        key={achievement.id}
                                        className={`p-4 rounded-lg border border-gray-300 text-center ${isAchievementEarned(achievement.id) ? '' : 'opacity-100'}`}
                                    >
                                        <img
                                            src={achievement.icon}
                                            alt={achievement.name}
                                            className="mx-auto mb-2"
                                            style={{
                                                width: '64px', // Adjust size as needed
                                                height: 'auto',
                                            }}
                                            title={achievement.description}
                                        />
                                        <div className="flex flex-col items-center">
                                            <p className="text-sm font-semibold min-h-[40px]">{achievement.name}</p> {/* Set a minimum height here */}
                                            <p
                                                className={`text-sm mt-1 ${isAchievementEarned(achievement.id) ? 'text-green-500' : 'text-red-500'}`}
                                            >
                                                {isAchievementEarned(achievement.id) ? 'Earned' : 'Locked'}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    )}
                    {activeTab === 'profiles' && (
                        <div className="relative">
                            {/* <h2 className="text-2xl font-bold mb-4">Your Car Profiles</h2> */}

                            {/* Fixed Button Container */}
                            <div className="sticky top-0 bg-white py-4 z-10 border-b border-gray-300 flex justify-center">
                                <button
                                    onClick={handleCreateProfile}
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                >
                                    Create New Profile
                                </button>
                                {isAdmin && (
                                    <button
                                        onClick={handleGenerateQR}
                                        className="ml-4 bg-yellow-500 text-black px-4 py-2 rounded-lg hover:bg-yellow-600"
                                    >
                                        Admin: Generate QR Codes
                                    </button>
                                )}
                            </div>
                            <div
                                className="overflow-y-auto mt-4"
                                style={{ maxHeight: 'calc(100vh - 350px)'}}  // Adjust height and padding
                            >
                                {profiles.length > 0 ? (
                                    <div className="grid grid-cols-1 gap-4">
                                        {profiles.map((profile) => {
                                            const firstCarImage = profile.carPictures?.length > 0
                                                ? profile.carPictures[0]
                                                : profile.carPicture
                                            return (
                                                <a
                                                    href={`/car-details/${profile.id}`}
                                                    className="mt-1 inline-block"
                                                    key={profile.id}
                                                >
                                                    <div className="bg-white rounded-lg p-4 border border-gray-300 flex flex-col items-start">
                                                        <h3 className="text-xl font-semibold mb-2">{profile.carModel}</h3> {/* Car model on a separate line */}
                                                        <div className="flex items-center">
                                                            <img
                                                                src={firstCarImage}
                                                                alt="Car Thumbnail"
                                                                className="w-20 h-20 object-cover rounded mr-4"
                                                                onError={(e) => e.target.src = `${process.env.PUBLIC_URL}/RaceCarPlaceholder.png`} // Handle broken images
                                                            />
                                                            <div className="flex-1">
                                                                <p className="text-sm">Driver: {profile.driverName}</p>
                                                                {scanStats[profile.id] && (
                                                                    <div className="mt-2 flex items-center space-x-4 text-xs">
                                                                        <div className="flex items-center">
                                                                            <FaEye className="text-yellow-500 mr-1" />
                                                                            <span>{scanStats[profile.id].totalScans || 0} Views</span>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <FaUsers className="text-yellow-500 mr-1" />
                                                                            <span>{scanStats[profile.id].uniqueVisitors || 0} Unique Visitors</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <p className="text-lg text-center mt-4">You haven't created any car profiles yet. Click Create New Profile above and create a profile for your car!</p>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'teams' && (
                        <div>
                            <h2 className="text-2xl font-bold mb-4">Your Teams</h2>
                            <button
                                onClick={() => navigate('/create-team')}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                            >
                                Create New Team
                            </button>
                            {ownedTeams.length > 0 ? (
                                <div className="grid grid-cols-1 gap-4">
                                    {ownedTeams.map((team) => (
                                        <div key={team.id} className="bg-white rounded-lg p-4 border border-gray-300">
                                            <h3 className="text-lg font-semibold">{team.teamName}</h3>
                                            <p>{team.teamDescription || 'No description available.'}</p>
                                            <button
                                                onClick={() => navigate(`/team-management/${team.id}`)}
                                                className="mt-2 bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600"
                                            >
                                                Manage Team
                                            </button>
                                            <button
                                                onClick={() => handleRemoveTeamClick(team.id)}
                                                className="mt-2 bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2"
                                            >
                                                Remove Team
                                            </button>
                                        </div>
                                    ))}
                                    {showRemoveModal && (
                                        <Modal
                                            title="Confirm Removal"
                                            onClose={() => setShowRemoveModal(false)}
                                            onConfirm={handleConfirmRemove}
                                            confirmText="Remove"
                                            cancelText="Cancel"
                                        >
                                            <p>Are you sure you want to remove this team? This action cannot be undone.</p>
                                        </Modal>
                                    )}
                                </div>
                            ) : (
                                <p>You do not own any teams.</p>
                            )}
                        </div>

                    )}
                    {activeTab === 'activity' && (
                        <div>

                            <h2 className="text-2xl font-bold mb-4">Your Activity</h2>
                            {recentScans.length > 0 && (
                                <>
                                    <div className="flex items-center space-x-4 mb-4">
                                        <select
                                            onChange={(e) => handleFilterChange(e.target.value)}
                                            value={filter}
                                            className="px-3 py-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                        >
                                            <option value="today" className="text-white">Today</option>
                                            <option value="thisWeek" className="text-white">This Week</option>
                                            <option value="thisMonth" className="text-white">This Month</option>
                                            <option value="last6Months" className="text-white">Last 6 Months</option>
                                            <option value="thisYear" className="text-white">This Year</option>
                                            <option value="all" className="text-white">All</option>
                                        </select>

                                        <button onClick={handleClearAll} className="bg-red-500 text-white px-4 py-2 rounded">
                                            Clear All Activity
                                        </button>
                                    </div>

                                </>
                            )}
                            {filteredScans.length > 0 ? (
                                <div className="grid grid-cols-1 gap-4">
                                    {
                                        filteredScans.map((scan, index) => {
                                            const firstCarImage = scan.carPictures?.length > 0
                                                ? scan.carPictures[0]
                                                : scan.carPicture
                                            return (
                                                <a href={`/car-details/${scan.id}`} key={index} className="mt-1 inline-block">
                                                    <div className="bg-white rounded-lg p-4 border border-gray-300 flex flex-col items-start">
                                                        <h4 className="text-lg font-semibold mb-2">{scan.carModel}</h4> {/* Car model on a separate line */}
                                                        <div className="flex items-center">
                                                            <img
                                                                src={firstCarImage}
                                                                alt="Car Thumbnail"
                                                                className="w-20 h-20 object-cover rounded mr-4"
                                                            />
                                                            <div>
                                                                <p className="text-sm">Driver: {scan.driverName}</p>
                                                                <p className="text-sm pt-2">Viewed {formatTimestamp(scan.timestamp)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                            )
                                        }
                                        )
                                    }
                                </div>
                            ) : (
                                <p className="text-sm">No activity yet.</p>
                            )}

                        </div>
                    )}
                    {activeTab === 'favorites' && (

                        <div className="mb-8">
                            <h2 className="text-2xl font-bold mb-4">Your Favorites</h2>
                            {favorites.length > 0 ? (
                                <div className="grid grid-cols-1 gap-4">
                                    {
                                        favorites.map((fav, index) => {

                                            const firstCarImage = fav.carPictures?.length > 0
                                                ? fav.carPictures[0]
                                                : fav.carPicture
                                            return (
                                                <a href={`/car-details/${fav.id}`} key={index} className="mt-1 inline-block">
                                                    <div className="bg-white rounded-lg p-4 border border-gray-300 flex flex-col items-start">
                                                        <h4 className="text-lg font-semibold mb-2">{fav.carModel}</h4> {/* Car model on a separate line */}
                                                        <div className="flex items-center">
                                                            <img
                                                                src={firstCarImage}
                                                                alt="Car Thumbnail"
                                                                className="w-20 h-20 object-cover rounded mr-4"
                                                            />
                                                            <div>
                                                                <p className="text-sm">Driver: {fav.driverName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            ) : (
                                <p className="text-sm">No saved favorites.</p>
                            )}
                        </div>

                    )}
                </div>

            </div>

        </div>
    );
}

export default UserProfiles;
