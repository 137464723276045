import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { db, auth } from './firebase'; // Firestore setup
import { collection, addDoc, getDoc, doc } from 'firebase/firestore'; // Firestore functions
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { onAuthStateChanged } from 'firebase/auth';

function AdminGenerateQR() {
  const [generatedCodes, setGeneratedCodes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numQRCodes, setNumQRCodes] = useState(1); // New state for the number of QR codes
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminRole = async (user) => {
      if (user) {
        const docRef = doc(db, 'roles', user.uid); // Check if the user has a document in the 'roles' collection
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().role === 'admin') {
          setIsAdmin(true);
        } else {
          navigate('/');
        }
      } else {
        navigate('/login');
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkAdminRole(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!isAdmin) {
    return <p>Access Denied: You are not an admin.</p>;
  }

  const generateQRCodes = async () => {
    const newCodes = [];
    for (let i = 0; i < numQRCodes; i++) {
      const docRef = await addDoc(collection(db, 'profiles'), {
        driverName: null,
        carModel: null,
        profilePicture: null,
        carPicture: null,
        socialMediaLinks: null,
        youtubeVideo: null,
      });

      const qrCodeURL = `https://fandrive.app/car-details/${docRef.id}`;
      newCodes.push({ id: docRef.id, qrCodeURL });
    }
    setGeneratedCodes(newCodes);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();

    for (let i = 0; i < generatedCodes.length; i++) {
      const qrCodeElement = document.querySelector(`#qrCode-${generatedCodes[i].id}`);
      const canvas = await html2canvas(qrCodeElement);
      const imgData = canvas.toDataURL('image/png');

      doc.setFontSize(16);
      doc.text(`QR Code ${i + 1}`, 105, 40, { align: 'center' });
      doc.addImage(imgData, 'PNG', 80, 60, 50, 50);
      const profileURL = `https://fandrive.app/car-details/${generatedCodes[i].id}`;
      doc.setFontSize(12);
      doc.text(profileURL, 105, 130, { align: 'center' });

      if (i < generatedCodes.length - 1) {
        doc.addPage();
      }
    }

    doc.save('Generated_QR_Codes.pdf');
  };

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center py-8 px-4">
      <h1 className="text-4xl font-bold mb-6">Admin - Generate QR Codes</h1>

      <label className="text-lg mb-2">Number of QR Codes to Generate:</label>
      <input
        type="number"
        value={numQRCodes}
        min="1"
        onChange={(e) => setNumQRCodes(Number(e.target.value))}
        className="mb-4 px-3 py-2 rounded-lg text-black"
      />

      <button onClick={generateQRCodes} className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
        Generate QR Codes
      </button>

      <div className="mt-6">
        {generatedCodes.map((code) => (
          <div key={code.id} className="my-4">
            <QRCodeCanvas id={`qrCode-${code.id}`} value={code.qrCodeURL} size={200} />
            <p>{code.qrCodeURL}</p>
          </div>
        ))}
      </div>

      {generatedCodes.length > 0 && (
        <button onClick={generatePDF} className="mt-6 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600">
          Download QR Codes PDF
        </button>
      )}
    </div>
  );
}

export default AdminGenerateQR;
